import $ from 'jquery'

export default class FinishedMessage {
  start() {
    this.closeFinishedMessage()
  }

  closeFinishedMessage() {
    $(document).on('ready turbolinks:load', function () {
      $('.js-finishedMessage--close').on('click', function () {
        $('.js-finishedMessage').hide()
      })
    })
  }
}
