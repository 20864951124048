import Tab from './Tab'
import ApplicationForm from './ApplicationForm'
import Alert from './Alert'
import Accordion from './Accordion'
import Messenger from './Messenger'
import ReturnAccordion from './ReturnAccordion'
import FinishedMessage from './FinishedMessage'
import TurbolinksOptimizer from './TurbolinksOptimizer'

export default class App {
  constructor() {
    this.tabController = new Tab()
    this.applicationForm = new ApplicationForm()
    this.alert = new Alert()
    this.messenger = new Messenger()
    this.accordion = new Accordion()
    this.returnAccordion = new ReturnAccordion()
    this.finishedMessage = new FinishedMessage()
    this.turbolinksOptimizer = new TurbolinksOptimizer()
  }

  start() {
    this.tabController.start()
    this.applicationForm.start()
    this.alert.start()
    this.messenger.start()
    this.accordion.start()
    this.returnAccordion.start()
    this.finishedMessage.start()
    this.turbolinksOptimizer.start()
  }
}
