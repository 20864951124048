import $ from 'jquery'

export default class Messenger {
  start() {
    this.openMessenger()
  }

  openMessenger() {
    $(document).on('ready turbolinks:load', function () {
      if ($('.js-messenger').length) {
        const href = $('.js-messenger').attr('href')
        setTimeout(function () {
          location.href = href;
        }, 500)
      }
    })
  }
}
